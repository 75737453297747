import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Img from "gatsby-image"

const LogosGrid: FC<Props> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/LogosGrid\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            logos {
              logo {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              height
            }
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)

  return (
    <div className={`${styles.grid} ${className}`}>
      {texts.logos.map((logo: any) => (
        <div className={styles.logoContainer} key={logo.alt}>
          <Img
            fluid={logo.logo.childImageSharp.fluid}
            alt={logo.alt}
            className={styles.logo}
            style={{ height: logo.height, width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      ))}
    </div>
  )
}

export default LogosGrid
